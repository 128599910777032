.menubar1,
.menubar2,
.menubar3 {
  width: 25px;
  height: 2px;
  background-color: #877d6f;
  margin: 6px 0;
  transition: 0.4s;
  border-radius: 5vw;
  z-index: 80;
}

.changebar .menubar1 {
  transform: translate(0, 5px) rotate(-45deg);
  z-index: 80;
}

.changebar .menubar2 {
  opacity: 0;
}

.changebar .menubar3 {
  transform: translate(0, -11px) rotate(45deg);
  z-index: 80;
}

.changebar {
  background-color: transparent;
  color: black;
  outline: none;
  z-index: 80;
}

.menu_bg {
  background: linear-gradient(
    to right bottom,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 1)
  );
  transition: 0.8s ease-in-out;
  position: fixed;
  z-index: 50;
  overflow-y: auto;
  max-height: 100vh;
  position: fixed;
  border-radius: 0px 30px 30px 0px;
}

.active_link {
  background-color: white;
  border-radius: 100%;
  color: black;
}
