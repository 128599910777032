.download_btn {
  width: 40px;
  height: 35px;
  border: none;
  border-radius: 10px;
  /* background-color: rgb(27, 27, 27); */
  background-color: white;
  border: solid 1px #444444;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  transition-duration: 0.3s;
  /* box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.11); */
}

.svgIcon {
  fill: #444444;
}

.icon2 {
  width: 18px;
  height: 5px;
  border-bottom: 1px solid #444444;
  border-left: 1px solid #444444;
  border-right: 1px solid #444444;
}

.tooltip {
  position: absolute;
  top: -150%;
  opacity: 0;
  background-color: rgb(60, 60, 60);
  /* background-color: lightgray; */
  color: white;
  padding: 2px 8px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 0.2s;
  pointer-events: none;
  letter-spacing: 0.5px;
  font-size: 12px;
}

.tooltip::before {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  background-color: rgb(60, 60, 60);
  /* background-color: lightgray; */
  background-size: 1000%;
  background-position: center;
  transform: rotate(45deg);
  bottom: -5%;
  transition-duration: 0.3s;
}

.download_btn:hover .tooltip {
  opacity: 1;
  transition-duration: 0.3s;
}

.download_btn:hover {
  background-color: #877d6f;
  transition-duration: 0.3s;
}

.download_btn:hover .icon2 {
  border-bottom: 1px solid rgb(255, 255, 255);
  border-left: 1px solid rgb(255, 255, 255);
  border-right: 1px solid rgb(255, 255, 255);
}

.download_btn:hover .svgIcon {
  fill: rgb(255, 255, 255);
  animation: slide-in-top 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.popup_form_Delete {
  height: auto;
  width: 80%;
  display: block;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(255, 255, 255);
  padding: 3%;
  border: 1px solid #877d6f;
  z-index: 2;
  border-radius: 10px;
}

@media (min-width: 1024px) {
  .popup_form_Delete {
    width: 30%;
    left: 55%;
    padding: 1%;
  }
}

.overlay_Delete {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.close_Delete {
  position: absolute;
  top: 2px;
  right: 8px;
  font-size: 20px;
  cursor: pointer;
  margin-left: auto;
  color: black;
}

.popup_form_Dashboard {
  height: 80%;
  width: 85%;
  display: block;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px 20px 50px 20px;
  border: 1px solid #ccc;
  z-index: 2;
  border-radius: 10px;
  color: #877d6f;
  /* z-index: 10; */
  overflow: hidden;
}

@media screen and (min-width: 1024px) {
  .popup_form_Dashboard {
    height: 550px;
    width: 50%;
    left: 55%;
    top: 50%;
  }
}

.overlay_Dashboard {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
}

.close_Dashboard {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.ant-table-thead .ant-table-cell {
  background-color: #e7eceb !important;
  opacity: 0.9;
}

.customCheckbox{
  accent-color: #877d6f;
  width: 15px; 
  height: 15px; 
}

/* Edit User page css starts from here */
.cancel_btn {
  --color: #877d6f;
  padding: 5px 1em;
  background-color: transparent;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s;
  font-weight: 400;
  border: 1px solid;
  font-family: inherit;
  color: var(--color);
  z-index: 1;
}

.cancel_btn::before,
.cancel_btn::after {
  content: "";
  display: block;
  width: 50px;
  height: 50px;
  transform: translate(-50%, -50%);
  position: absolute;
  border-radius: 50%;
  z-index: -1;
  background-color: var(--color);
  transition: 1s ease;
}

.cancel_btn::before {
  top: -1.5em;
  left: -1.5em;
}

.cancel_btn::after {
  left: calc(100% + 1.5em);
  top: calc(100% + 1.5em);
}

.cancel_btn:hover::before,
.cancel_btn:hover::after {
  height: 410px;
  width: 410px;
}

.cancel_btn:hover {
  color: rgb(255, 255, 255);
}

.cancel_btn:active {
  filter: brightness(0.8);
}

.done_btn {
  --color: white;
  margin-left: 8px;
  padding: 5px 1.4em;
  background-color: #877d6f;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s;
  font-weight: 400;
  border: 1px solid;
  font-family: inherit;
  color: var(--color);
  z-index: 1;
}

.done_btn::before,
.done_btn::after {
  content: "";
  display: block;
  width: 50px;
  height: 50px;
  transform: translate(-50%, -50%);
  position: absolute;
  border-radius: 50%;
  z-index: -1;
  background-color: var(--color);
  transition: 1s ease;
}

.done_btn::before {
  top: -1.5em;
  left: -1.5em;
}

.done_btn::after {
  left: calc(100% + 1.5em);
  top: calc(100% + 1.5em);
}

.done_btn:hover::before,
.done_btn:hover::after {
  height: 410px;
  width: 410px;
}

.done_btn:hover {
  color: #877d6f;
}

.done_btn:active {
  filter: brightness(0.8);
}

/* Edit user page css ends here */

/* Sign in page css starts from here */
.container_SignIn {
  height: auto;
  width: 500px;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
}

.btn {
  height: 40px;
  width: 500px;
  margin: 20px auto;
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #efefef;
}

.login,
.signup {
  border: none;
  outline: none;
  background-color: transparent;
  position: relative;
  cursor: pointer;
}

.login {
  margin-left: 10px;
}

.signup {
  margin-right: -25px;
}

.slider {
  height: 30px;
  width: 245px;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  top: 25px;
  left: 5px;
  transition: all 0.5s ease-in-out;
}

.moveslider {
  left: 250px;
}

.form-section {
  width: 1000px;
  padding: 20px 0;
  display: flex;
  position: relative;
  transition: all 0.5s ease-in-out;
  left: 0px;
}

.form-section-move {
  left: -500px;
}

.login-box,
.signup-box {
  height: 60%;
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 40px;
}

.login-box {
  gap: 10px;
}

.signup-box {
  gap: 10px;
}

.ele {
  height: 60px;
  width: 400px;
  outline: none;
  border: none;
  color: rgb(77, 77, 77);
  background-color: rgb(240, 240, 240);
  border-radius: 50px;
  padding-left: 30px;
  font-size: 18px;
}

.clkbtn {
  height: 40px;
  width: 400px;
  border-radius: 50px;
  background-color: black;
  color: white;
  border: none;
  cursor: pointer;
}

/* For Responsiveness of the page */

@media screen and (max-width: 650px) {
  .container_SignIn {
    height: auto;
    width: 300px;
  }

  .btn {
    height: 40px;
    width: 300px;
    margin: 20px auto;
  }

  .login,
  .signup {
    font-size: 13px;
  }

  .slider {
    height: 30px;
    width: 145px;
    left: 5px;
  }

  .moveslider {
    left: 150px;
  }

  .form-section {
    width: 600px;
  }

  .form-section-move {
    left: -300px;
  }

  .login-box,
  .signup-box {
    height: 50%;
    width: 300px;
  }

  .ele {
    height: 50px;
    width: 250px;
  }

  .clkbtn {
    width: 300px;
  }
}

@media screen and (max-width: 320px) {
  .container_SignIn {
    height: auto;
    width: 250px;
  }

  .btn {
    height: 40px;
    width: 220px;
    margin: 20px auto;
  }

  .slider {
    height: 30px;
    width: 100px;
    left: 20px;
  }

  .moveslider {
    left: 130px;
  }

  .form-section {
    width: 500px;
  }

  .form-section-move {
    left: -250px;
  }

  .login-box,
  .signup-box {
    height: 50%;
    width: 250px;
  }

  .ele {
    height: 50px;
    width: 220px;
  }

  .clkbtn {
    height: 35px;
    width: 220px;
  }
}

/* Sign in page css ends here */

/* Edit property page css starts from here */
.popup_form_PriceCalendar {
  height: auto;
  width: auto;
  max-width: 95%;
  display: block;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 1);
  padding: 3%;
  border: 1px solid #ccc;
  z-index: 200;
  border-radius: 10px;
  max-height: 86vh;
  overflow-y: auto;
}

@media (min-width: 1024px) {
  .popup_form_PriceCalendar {
    width: auto;
    max-width: 85%;
    left: 54%;
    padding: 1%;
    max-height: 95vh;
  }
}

.overlay_PriceCalendar {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.close_PriceCalendar {
  position: absolute;
  top: 2px;
  right: 8px;
  font-size: 20px;
  cursor: pointer;
  margin-left: auto;
  color: #877d6f;
  font-weight: bold;
}

.rdrCalendarWrapper {
  width: 60em !important;
  font-size: 12px !important;
}

.rdrMonth {
  width: 60em !important;
  /* height: 20em!important;  */
}

.rdrDay {
  height: 5em !important;
  line-height: 5em !important;
}
/* Edit property page css ends here */
